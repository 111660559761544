import React, {useState} from 'react';
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';
import OptimizeExample from './OptimizeExample';
import PopularExample from './PopularExample';
import WrapperStyle from './examples.style';
import Drawer from '../../components/UiComponent/drawer';
import ScriptTag from 'react-script-tag';
import {StaticImage} from 'gatsby-plugin-image';

const OurSolutions = () => {
  const [visible, setVisible] = useState(false);
  const [promotionId, setPromotionId] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState(null);
  const showDrawer = (id, title) => {
    setVisible(true);
    setPromotionId(id);
    setDrawerTitle(title)
  };
    return (
      <WrapperStyle>
        <section className="solutions-area pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <h1>Youtubeプレゼント企画の事例・サンプル
              </h1>
              <p>
                Youtuberから企業チャンネルまで多くのプレゼント企画やキャンペーンを簡単に作成することができます。Youtubeのプレゼント企画は売上、リード獲得、エンゲージメントを高める非常に有効な方法です。
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <a data-retach-widget="embed" href="https://retach.biz/promotions/dc845135c428cde0ae" rel="nofollow">リタッチ</a>
                <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
              </div>
            </div>
          </div>
        </section>
        <OptimizeExample  types={['youtubeGiveaway','youtubeSecret','companyCsr']} showDrawer={showDrawer}/>
        <section className="ptb-100 top-question" >
          <div className="container">
            <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Question
                    </span>
              <h2 id="question">よくあるご質問</h2>
            </div>
            <div className="faq-accordion">
              <Accordion allowMultipleExpanded={true}>
                <AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Youtubeのプレゼント企画は利用規約に違反しませんか？
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        はい。違反しておりません。
                        金銭やインセンティブを渡してチャンネル登録やコメント・高評価を行う行為は禁止されておりますが、<a href="https://www.youtube.com/static?template=terms&hl=ja&gl=JP">「利用規約」</a>及び<a href="https://support.google.com/youtube/answer/1620498?hl=ja">「YouTube コンテストのポリシーとガイドライン」</a>に反しない範囲で開催することは可能です。
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        秘密の合言葉企画は生配信にも使用できますか？
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        はい。Youtubeの生配信で合言葉企画を行うことは非常に有効です。視聴継続率が上がり生配信の視聴者だけしか応募できないので、ファンへのエンゲージメントが高くなります。
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Youtubeの配信でLINEの友達になることは何かメリットがありますか？
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        動画配信の時間にLINEで通知を送ることで、視聴者が配信を見逃すことが減り、初動が高くなりYoutubeのアルゴリズムにいい影響を与えます。それ以外にもメールやSNSよりLINEは1対1のコミュニケーションがとれるので最適です。
                        <ul>
                          <li>動画配信・イベントを通知できる</li>
                          <li>コミュニケーションがとれる</li>
                          <li>属性分析ができる</li>
                        </ul>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      インスタントウィンはYoutubeの利用規格に反しますか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      インスタントウィンはその場で抽選され応募数が集まりやすいプレゼント企画ですが、応募者全員にプレゼントを渡してしまうことは利用規約に反しますのでお勧めしておりません。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

              </Accordion>
            </div>
          </div>
        </section>
        <PopularExample exclude={['youtubeGiveaway', 'twitterMultipleAccounts']} excludeProviders={['youtube']} showDrawer={showDrawer}/>
        <Drawer visible={visible}id={promotionId} drawerTitle={drawerTitle} setVisible={setVisible}/>
      </WrapperStyle>
    )
};

export default OurSolutions